<template>
  <div class="relative bg-white px-4 pb-6 pt-4 shadow-xl md:w-80 !px-3 !pb-4" data-test-id="vf-toast">
    <div class="flex grow gap-x-2 wrap">
      <div class="w-full flex items-center">
        <vf-icon
          v-if="type || icon"
          :name="type"
          :src="icon?.src"
          :size="icon?.size || 'lg'"
          class="shrink-0"
          :class="{
            'c-green-30': type === 'success',
                        'c-red-30': type === 'error',
            'self-end': icon,
          }"
        />
        <div v-if="title || message" class="ml-2 mt-2 grow text-sm !mt-0" style="margin-right: 2.125rem">
          <p v-if="title" class="mb-2 fw-bold sr-only">
            {{ title }}
          </p>
          <p v-if="message">
            <base-interpolator :content="message">
              <template #bold="{ args: [message] }">
                <span class="fw-bold">
                  {{ message }}
                </span>
              </template>
            </base-interpolator>
          </p>
        </div>
        <base-button
          class="absolute right-0 top-0 mr-4 mt-4 p-1 !relative !m-0 !p-0"
          :aria-label="$t.close"
          @click="$emit('close')"
        >
          <vf-icon name="close" size="md" />
        </base-button>
      </div>
      <vf-button
        v-for="({ label, to, click }, key) in ctas"
        :key
        :to
        size="xs"
        :variant="key ? 'secondary' : 'primary'"
        class="mt-6 grow"
        @click="clickCta(click)"
      >
        {{ label }}
      </vf-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ToastProps } from '#types/notification'

withDefaults(
  defineProps<ToastProps>(),
  { type: 'success' }
)

const emit = defineEmits<{
  close: []
}>()

const clickCta = (ctaClickHandler?) => {
  ctaClickHandler?.()
  emit('close')
}
</script>

<template>
  <component
    :is="to ? BaseLink : BaseButton"
    class="w-full f-col items-center b-t-3 b-transparent px-4 pb-6 text-center duration hover:b-brand-2 "
    :class="{ 'pointer-events-none': disabled }"
    :to
    :disabled
    :aria-labelledby="tileTitleId"
    :aria-describedby="tileDescriptionId"
    style="gap: 0.3125rem; padding-top: 1.3125rem"
    data-test-id="vf-tile"
  >
    <vf-icon size="2xl" :name="icon" />
    <span :id="tileTitleId" class="title-3">
      {{ title }}
    </span>
    <span v-if="description" :id="tileDescriptionId" class="text-sm c-black">
      <slot name="description">
        {{ description }}
      </slot>
    </span>
  </component>
</template>

<script lang="ts" setup>
import { BaseButton, BaseLink } from '#components'
import type { IconName } from '#types/icon'

defineProps<{
  icon: IconName
  title: string
  description?: string
  to?: string
  disabled?: boolean
}>()

const id = useId()
const tileTitleId = `tile-title-${id}`
const tileDescriptionId = `tile-desc-${id}`
</script>

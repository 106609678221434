<template>
  <vf-carousel
    v-if="items.length > 0"
    :class-container="{ [`lg:grid lg:cols-2 ${brandClasses.carousel}`]: items?.length > 1 }"
    :loop="gallery.loop"
    data-test-id="vf-media-gallery"
    @paginate="handleChange"
    @scroll-start="handleChange"
    @to-next="handleChange"
    @to-prev="handleChange"
  >
    <div
      v-for="(item, i) in items"
      :key="i"
      class="group relative w-full"
      :style="{ 'aspect-ratio': gallery.presets.src.width / gallery.presets.src.height }"
    >
      <template v-if="item.type === 'image'">
        <base-button class="w-full" data-test-id="vf-media-gallery-expand" @click="$emit('expand', i, 'img')">
          <base-pinch-zoom>
            <base-picture
              :src="item.src"
              :alt="item.alt"
              :width="items.length === 1 ? gallery.presets.srcZoom.width : gallery.presets.src.width"
              :height="items.length === 1 ? gallery.presets.srcZoom.height : gallery.presets.src.height"
              :lazy="i >= 4"
              class="w-full <md:w-screen <md:px-5"
              style="cursor: zoom-in;"
            />
          </base-pinch-zoom>
          <span class="sr-only">{{ $t.expandSlide }}</span>
          <vf-icon
            v-if="showExpandIcon"
            :name="iconName"
            :size="iconSize"
            class="absolute bottom-0 right-0 z-2 mb-2 mr-2 duration focus:op-100 lg:op-0 lg:group-hover:op-100"
          />
        </base-button>

        <slot v-bind="{ i }" />

        <base-button
          v-if="$feature.showShopSimilar && i === 0 && syteReady"
          class="syte-discovery absolute bottom-0 left-0 z-2 mb-2 ml-2 flex focus:op-100"
          :sku="productId"
          :data-image-src="item.src"
          data-test-id="vf-media-gallery-shop-similar"
          data-placement="PDP-main"
        >
          <vf-icon name="objective" size="md" />
          <span class="ml-2 text-md ">
            {{ $t.shopSimilar }}
          </span>
        </base-button>
      </template>

      <base-video
        v-if="item.type === 'video'"
        ref="videos"
        :src="item.src"
        :meta="item.meta"
        controls
        :poster="item.meta?.poster"
        :loop="item.meta?.loop"
        class="absolute-0 full"
        @play="handlePlay(item)"
      />
    </div>

    <template #prev="{ active, action }">
      <base-button
        class="absolute left-0 top-1/2 z-2 duration -mt-4 lg:hidden -scale-x-100"
        :class="[{ 'pointer-events-none op-0': !gallery.loop && !active, 'hidden': !showPrevNext || items.length < 2 }]"
        :aria-label="$t.previous"
        data-test-id="vf-carousel-arrow-left"
        @click.stop="action"
      >
        <vf-icon name="chevron" size="lg" />
      </base-button>
    </template>

    <template #next="{ active, action }">
      <base-button
        class="absolute right-0 top-1/2 z-2 duration -mt-4 lg:hidden"
        :class="[{ 'pointer-events-none op-0': !gallery.loop && !active, 'hidden': !showPrevNext || items.length < 2 }]"
        :aria-label="$t.next"
        data-test-id="vf-carousel-arrow-right"
        @click.stop="action"
      >
        <vf-icon name="chevron" size="lg" />
      </base-button>
    </template>

    <template v-if="showPagination" #pagination="{ activeItem, action, pages }">
      <base-scroll
        v-if="thumbnails"
        class-container="flex pt-4 space-x-2 lg:hidden wrap"
        data-test-id="vf-media-gallery-pagination"
      >
        <div
          v-for="(item, i) in items"
          :key="i"
          class="w-12 flex cursor-pointer center b b-transparent"
          :class="{ '!b-grey-10': i === activeItem, 'relative': item.type === 'video' }"
          :style="{ 'aspect-ratio': gallery.presets.src.width / gallery.presets.src.height }"
          :aria-current="i === activeItem"
          @click="action(i)"
        >
          <base-picture :src="item.type === 'video' ? item.meta.poster : item.src" />
          <vf-icon v-if="item.type === 'video'" name="play" class="absolute" />
        </div>
      </base-scroll>
      <div
        v-else-if="pagination && pages"
        class="flex justify-center pb-6 pt-4 lg:hidden space-x-2"
        data-test-id="vf-media-gallery-pagination"
      >
        <div
          v-for="(_, i) in items"
          :key="i"
          class="h-2 w-2 b b-grey-40 rounded-full"
          :class="{ 'bg-grey-10 b-grey-10 ': activeItem === i }"
          :aria-current="i === activeItem || undefined"
        />
      </div>
    </template>
  </vf-carousel>
  <div v-else class="bg-grey-80 py-1/2 text-center" style="line-height: 0">
    {{ $t.imageUnavailable }}
  </div>
</template>

<script lang="ts" setup>
import type { ImageItem, VideoItem } from '#types/media'
import type { BaseVideo as BaseVideoType } from '#components'

defineProps<{
  /**
   * List of configs that will be used to render slides and thumbnails
   */
  items: (ImageItem | VideoItem)[]
  /**
   * Defines whether pagination dots should be displayed under carousel. Tablet/Mobile only
   */
  pagination?: boolean
  /**
   * Defines whether thumbnails should be displayed under carousel. This property is an alternative for "pagination" and has higher priority. Tablet/Mobile only
   */
  thumbnails?: boolean
  productId?: string
  loop?: boolean
}>()

defineEmits<{
  /**
   * Emits when 'expand' icon or image is clicked
   */
  expand: [activeIndex: number, element: string]
}>()

const { $feature } = useNuxtApp()
const {
  brandClasses,
  showExpandIcon,
  showPagination,
  showPrevNext,
  iconSize,
  iconName
} = useAppConfig().components.vf.mediaGallery
const { gallery } = useAppConfig().pages?.pdp || {}
const { init: initSyte, ready: syteReady } = useSyte()
if ($feature.showShopSimilar) initSyte()

const videos = ref<typeof BaseVideoType[]>([])

const handlePlay = ({ src }) => videos.value.forEach((video) => video.src !== src && video.pause())

const handleChange = () => videos.value.forEach((video) => video.pause())
</script>
